import React from 'react';

import './App.css';
import { ZoomMtg } from '@zoomus/websdk';
import { v4 as uuidv4 } from 'uuid';

ZoomMtg.setZoomJSLib('https://source.zoom.us/2.9.5/lib', '/av');

ZoomMtg.preLoadWasm();
ZoomMtg.prepareJssdk();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');

function App() {

  const meetingId = document.location.pathname.replace('/', '');
  const email = document.location.search.indexOf('?email=') !== -1 ? document.location.search.split('?email=')[1] : `${uuidv4()}@guest.com`;

  // setup your signature endpoint here: https://github.com/zoom/meetingsdk-sample-signature-node.js
  var signatureEndpoint = process.env.REACT_APP_SIGNATURE_ENDPOINT
  // This Sample App has been updated to use SDK App type credentials https://marketplace.zoom.us/docs/guides/build/sdk-app
  var sdkKey = process.env.REACT_APP_SDK_KEY;
  var meetingNumber = meetingId;
  var role = 0
  var leaveUrl = document.location.href;
  var userName = 'React'
  var userEmail = email;
  var passWord = ''
  // pass in the registrant's token if your meeting or webinar requires registration. More info here:
  // Meetings: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/meetings#join-registered
  // Webinars: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/webinars#join-registered
  var registrantToken = ''

  function getSignature(e) {
    e.preventDefault();

    fetch(signatureEndpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        meetingNumber: meetingNumber,
        role: role
      })
    }).then(res => res.json())
    .then(response => {
      startMeeting(response.signature)
    }).catch(error => {
      console.error(error)
    })
  }

  function startMeeting(signature) {
    document.getElementById('zmmtg-root').style.display = 'block'

    ZoomMtg.init({
      leaveUrl: leaveUrl,
      success: (success) => {
        console.log(success)

        ZoomMtg.join({
          signature: signature,
          meetingNumber: `${meetingNumber}`,
          userName: userName,
          sdkKey: sdkKey,
          userEmail: userEmail,
          passWord: passWord,
          tk: registrantToken,
          success: (success) => {
            console.log(success)
          },
          error: (error) => {
            console.log(error)
          }
        })

      },
      error: (error) => {
        console.log(error)
      }
    })
  }


  return (
    <div className="App">
      <main>
        <h1>uBelong Zoom</h1>
        <button onClick={getSignature}>Acceder a reunión</button>
      </main>
    </div>
  );
}

export default App;
